<template>
    <div>
        <full-overlay :show="loading" />
        <b-button-group>
            <b-button variant="success" @click="add">Добавить</b-button>
            <b-button variant="primary" @click="getList(1)">Обновить</b-button>
        </b-button-group>
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template #cell(name)="{ value }">
                <div>{{ value.ru }}</div>
                <div>{{ value.en }}</div>
            </template>
            <template #cell(country)="{ value }">
                {{ value.name.ru }}
            </template>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="edit(id)">Редактировать</b-button>
                    <b-button @click="content(id)">Описание</b-button>
                    <b-button :to="{ name: 'Spectacle', params: { id } }">
                        Спектакли
                    </b-button>
                    <b-button @click="remove(id)" variant="danger" v-if="false">
                        Удалить
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-pagination
            :value="pg"
            :total-rows="hasNext ? pg + 1 : pg"
            per-page="1"
            @change="getList"
            limit="1"
            hide-goto-end-buttons
            prev-text="Назад"
            next-text="Вперед"
        />
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="lg"
        >
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Имя">
                            <b-form-input v-model="modal.name.ru" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Имя">
                            <b-form-input v-model="modal.name.en" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Страна">
                            <b-form-select
                                v-model="modal.country.id"
                                :options="countries"
                                value-field="id"
                                text-field="name.ru"
                                required
                            >
                                <template #first>
                                    <b-form-select-option
                                        :value="null"
                                        disabled
                                    >
                                        -- Выберите страну --
                                    </b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group label="Загрузка постера">
                    <b-form-file
                        v-model="modal.poster"
                        accept="image/jpeg, image/png"
                        :required="modal.id == 0"
                    />
                </b-form-group>
                <div class="poster" v-if="modal.id > 0">
                    <img
                        :src="`${poster}/${modal.id}/poster.jpg?${Date.now()}`"
                    />
                </div>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
        <content-editor
            ref="editor"
            get-url="/participant/:id/content"
            send-url="/participant/:id/content"
            :media-url="poster"
        />
    </div>
</template> 

<style lang="scss" scoped>
.poster {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
        max-width: 700px;
        max-height: 600px;
    }
}
</style>

<script>
import ContentEditor from "@c/ContentEditor.vue";
import FullOverlay from "@c/FullOverlay.vue";
export default {
    name: "Participant",
    components: {
        ContentEditor,
        FullOverlay,
    },
    data() {
        return {
            list: [],
            poster: "",
            loading: false,
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "name",
                    label: "Имя",
                },
                {
                    key: "country",
                    label: "Страна",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
            modal: this.getModal(),
            pg: 1,
            hasNext: false,
            countries: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("name[ru]", this.modal.name.ru);
            body.append("name[en]", this.modal.name.en);
            body.append("country", this.modal.country.id);
            if (this.modal.poster) {
                body.append("poster", this.modal.poster);
            }
            await this.$fetchAdmin("/participant/save", {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
            this.getList();
        },
        async getList(pg = 1) {
            this.loading = true;
            const { list, poster, hasNext, countries } = await this.$fetchAdmin(
                `/participant?pg=${pg}`
            ).then((r) => r.json());
            this.list = list;
            this.poster = `${this.$store.state.api}${poster}`;
            this.hasNext = hasNext;
            this.countries = countries;
            this.pg = pg;
            this.loading = false;
        },
        add() {
            this.modal = this.getModal();
            this.modal.show = true;
        },
        getModal: () => ({
            id: 0,
            name: {
                ru: "",
                en: "",
            },
            poster: null,
            country: {
                id: null,
            },
            show: false,
        }),
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(
                `/participant/${id}`
            ).then((r) => r.json());
            this.modal = Object.assign(this.getModal(), one);
            this.modal.show = true;
            this.loading = false;
        },
        content(id) {
            this.$refs.editor.open(id);
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            this.loading = true;
            await this.$fetchAdmin(`/participant/${id}`, {
                method: "DELETE",
            }).then((r) => r.json());
            this.loading = false;
            this.getList();
        },
    },
};
</script>